import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Registration from './Registration';
import AttendeesList from "./AttendeesList";
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Registration />} />
    <Route exact path="/list" element={<AttendeesList />} />

      {/* </Route> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;