import React, { useEffect, useState } from "react";
import "./AttendeesList.css"; // Custom CSS for styling
import { toast, Toaster } from "react-hot-toast"; // Optional for notifications

const AttendeesList = () => {
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch attendees from backend
    fetch("http://spms.phrmdo-catanduanes.com:3004/attendees")
      .then((response) => response.json())
      .then((data) => {
        setAttendees(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching attendees:", error);
        toast.error("Failed to fetch attendees. Please try again.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading attendees...</p>;
  }

  return (
    <div className="container mt-5">
      <h3>Attendees List</h3>
      <Toaster />
      {attendees.length === 0 ? (
        <p>No attendees registered yet.</p>
      ) : (
        <table className="table table-bordered table-striped mt-3">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Department</th>
              <th>Position</th>
              <th>Mobile</th>
              <th>Signature</th>
            </tr>
          </thead>
          <tbody>
            {attendees.map((attendee, index) => (
              <tr key={index}>
                <td>{attendee.firstname}</td>
                <td>{attendee.middlename}</td>
                <td>{attendee.lastname}</td>
                <td>{attendee.department}</td>
                <td>{attendee.position}</td>
                <td>{attendee.mobile}</td>
                <td>
                  {/* Display signature as an image if available */}
                  {attendee.signature ? (
                    <img
                      src={attendee.signature}
                      alt="Signature"
                      style={{ width: "100px", height: "50px" }}
                    />
                  ) : (
                    "No signature"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AttendeesList;
