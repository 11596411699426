import React, { useState, useEffect, useRef } from "react";
import "./Registration.css"; // Import custom CSS for additional styling
import { toast, Toaster } from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas"; // Import SignatureCanvas

const Registration = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    nameExt: "",
    department: "",
    position: "",
    mobile: "",
    signature: "", // Store signature data here
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [suggestions, setSuggestions] = useState({
    departments: [],
    positions: [],
  });

  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);

  const sigCanvas = useRef({}); // Reference to the signature canvas

  useEffect(() => {
    // Fetch department and position suggestions from the backend
    fetch("http://spms.phrmdo-catanduanes.com:3004/suggestions")
      .then((response) => response.json())
      .then((data) => {
        setSuggestions(data);
      })
      .catch((err) => console.error("Error fetching suggestions:", err));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "department") {
      const filtered = suggestions.departments.filter((dept) =>
        dept.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredDepartments(filtered);
    }

    if (name === "position") {
      const filtered = suggestions.positions.filter((pos) =>
        pos.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredPositions(filtered);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Convert signature to image format (base64)
    const signatureImage = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    // Include signature in the form data
    const fullFormData = {
      ...formData,
      signature: signatureImage,
    };

    // Submit the form data to the backend
    fetch("http://spms.phrmdo-catanduanes.com:3004/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fullFormData),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsSubmitting(false);
        if (data.success) {
          toast.success("Registration successful!");
          clearForm(); // Clear form fields after successful submission
        } else {
          toast.error("Registration failed!");
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error("Something went wrong. Please try again later.");
      });
  };

  // Function to clear all form fields
  const clearForm = () => {
    setFormData({
      firstname: "",
      middlename: "",
      lastname: "",
      nameExt: "",
      department: "",
      position: "",
      mobile: "",
      signature: "",
    });
    sigCanvas.current.clear(); // Clear the signature canvas
  };

  return (
    <div className="container">
      <div className="registration-form mt-5 p-4 rounded shadow-lg">
        <h4 className="mb-4">
          <strong>SOPA 2024</strong> <small>Attendee Information</small>
        </h4>
        <Toaster />
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="firstname" className="form-label">
                First Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="middlename" className="form-label">
                Middle Name
              </label>
              <input
                type="text"
                className="form-control"
                id="middlename"
                name="middlename"
                value={formData.middlename}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="lastname" className="form-label">
                Last Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-md-2">
              <label
                htmlFor="nameExt"
                className="form-label"
                style={{ fontSize: "smaller" }}
              >
                Name Ext.
              </label>
              <input
                type="text"
                className="form-control"
                id="nameExt"
                name="nameExt"
                value={formData.nameExt}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="department" className="form-label">
              Department/Division *
            </label>
            <input
              type="text"
              className="form-control"
              id="department"
              name="department"
              value={formData.department}
              onChange={handleChange}
              list="department-suggestions"
              required
            />
            <datalist id="department-suggestions">
              {filteredDepartments.map((dept, index) => (
                <option key={index} value={dept} />
              ))}
            </datalist>
          </div>

          <div className="mb-3">
            <label htmlFor="position" className="form-label">
              Position *
            </label>
            <input
              type="text"
              className="form-control"
              id="position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              list="position-suggestions"
              required
            />
            <datalist id="position-suggestions">
              {filteredPositions.map((pos, index) => (
                <option key={index} value={pos} />
              ))}
            </datalist>
          </div>

          <div className="mb-3">
            <label htmlFor="mobile" className="form-label">
              Mobile Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>

          {/* Signature Field */}
          <div className="mb-3">
            <label className="form-label">Signature</label>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "signature-canvas" }}
              ref={sigCanvas}
            />
            <button type="button" className="btn btn-secondary mt-2" onClick={() => sigCanvas.current.clear()}>
              Clear Signature
            </button>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Registration;
